import * as React from "react"
import PropTypes from "prop-types"

import { GlobalStyle } from "../../styles/global"

import * as S from "./styles"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <S.Wrapper>
        <main>{children}</main>
      </S.Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
